export const useBaseUrl = () => {
    const { public: { baseURL }} = useRuntimeConfig();

    /**
     * Generate an absolute path to an asset.
     * @param path
     */
    const asset = (path: MaybeRefOrGetter<Readonly<string>> = '') => {
        return `${baseURL}${toValue(path)}`;
    };

    return {
        baseurl: baseURL,
        asset
    };
};
